// Use this tool to pick a primary & complimentary accent color:
// https://material.io/design/color/the-color-system.html#tools-for-picking-colors

// App font
$font: Roboto,'Helvetica Neue',Helvetica,Arial,sans-serif;

// Customize the colors here
$primaryColor: #009e75;
$accentColor: #002a9e;
$warnColor: #f93e3e;
$backgroundColor: #b4e2d5;
