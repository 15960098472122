// ------- For quill editor for taxon descriptions
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "~@angular/material/theming";

@import "./styles/_palette-gen.import";
@import "./styles/theme.import";

@import "bootstrap/dist/css/bootstrap.min.css";


// ----- Build the custom theme
@include mat-core();

$primaryPalette: mat-palette(createPalette($primaryColor));
$accentPalette: mat-palette(createPalette($accentColor));
$warnPalette: mat-palette(createPalette($warnColor));
$backgroundPalette: mat-palette(createPalette($backgroundColor));

$app-theme: mat-light-theme((
    color: (
        primary: $primaryPalette,
        accent: $accentPalette,
        warn: $warnPalette,
        background: $backgroundPalette
    )
));

@include angular-material-theme($app-theme);

/////SYMBIOTA2 CUSTOM STYLES/////

.symbiota2-info-mat-card{
    padding-top: 12px;
    color: #6C6C6C;
    min-height: 150px;

    mat-card-title{
        color: $accentColor;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 1rem;

        mat-icon{
            padding-right: .5rem;
        }
    }

    mat-card-content{
        padding-left: 1rem;
        font-weight: 300;
    }
}

.symbiota2-primary-info-mat-card{
    padding-top: 12px;
    color: white;
    min-height: 150px;
    background-color: $primaryColor;

    a:link{
        color: white;
        text-decoration: underline;
    }

    mat-card-title{
        color: white;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 1rem;

        mat-icon{
            padding-right: .5rem;
        }
    }

    mat-card-content{
        padding-left: 1rem;
        font-weight: 400;
    }
}

* {
    font-family: $font;
}

.mat-icon{
    font-family: 'Material Icons' !important;
  }

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    scroll-behavior: smooth !important;
}

body {
    -webkit-font-smoothing: antialiased;
    background-color: $backgroundColor;
}

a:link{
    text-decoration: none;
    color: $primaryColor;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: underline;
}

.mat-typography {
    font: initial;
}

label {
    font-size: 13px;
    line-height: 1.5;
    display: block;
}

fieldset {
    border: 2px solid grey;
    border-radius: 5px;
}

legend {
    padding: 0 5px;
    color: grey;
}

::ng-deep.mat-simple-snackbar{
    text-align: center !important;
    justify-content: center !important;
}

.error-snackbar {
    background-color: $warnColor;
    text-align: center !important;
    font-weight: bold;
}

.message-snackbar {
    background-color: #49cc90;
    text-align: center !important;
    font-weight: bold !important;
}

.dynamic-tab-content {
    padding: 20px;
}

.flex-container {
    display: flex;
}

.error-text {
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1;
    background: #B52D30;
    color: #fff;
    border-radius: 0 0 3px 3px;
    padding: 6px 10px;
}

.input-hint-text {
    line-height: 1.1em;
    font-size: 75%;
}

.clear {
    clear: both;
}

.bold {
    font-weight: bold;
}

.v-align-center {
    vertical-align: middle;
}

.text-center {
    text-align: center;
}

.text-start {
    text-align: start;
}

.text-end {
    text-align: end;
}

.text-danger {
    color: $warnColor;
}

.text-gray {
    color: gray;
}

.text-ok {
    color: $primaryColor;
}

.invisible-btn {
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.mat-dialog-panel {
    min-width: 60rem;
    max-width: 90%;

    @media only screen and (max-width: 600px) {
        min-width: 0;
        max-width: 100%;
    }
}

.checklist-taxon-form {
    z-index: 50 !important;
}

.checklist-upload-report-box {
    z-index: 100 !important;
}

div.checklist_img_full_size_overlay body {
    background-color: grey;
    z-index: 99999 !important;
}

@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";

